@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.video {
	position: relative;

	video {
		position: relative;
		margin: $gutter auto;
		display: block;
		max-width: 100%;
	}

	.video-btn {
		top: 55%;
		@include tablet() {
			top: 38%;
		}
	}
}

.playButton {
	display: none;
	width: 80px;
	text-indent: -9999px;
	height: 80px;
	border-radius: 50%;
	background-image: url(/images/icon-play--white.svg);
	background-repeat: no-repeat;
	background-size: 30% auto;
	background-color: rgba(255, 255, 255, 0.3);
	border: 2px solid $color-lightest;
	background-position: center center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.4s;
	position: absolute;

	@include tablet() {
		display: block;
	}

	&.isPlaying {
		opacity: 0;
	}
}
